import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Template = ({ data }) => {
	const post = data.markdownRemark;
	const sitePage = data.sitePage;

	return (
		<Layout>
			<SEO
				title={post.frontmatter.title}
				ogImage={
					post.frontmatter.indexImage
						? `https://dgital.com${post.frontmatter.indexImage.childImageSharp.resolutions.src}`
						: undefined
				}
				ogUrl={sitePage?.path ? `https://dgital.com${sitePage.path}` : undefined}
				description={post.frontmatter.description ?? post.frontmatter.title}
			/>
			<div className="container blog-article p-4">
				<div>
					<div className="text-3xl uppercasehover:text-brand-green mb-8">
						<h1>{post.frontmatter.title}</h1>
					</div>
					{/* <h2 className="mb-8">{post.frontmatter.date}</h2> */}
					<div dangerouslySetInnerHTML={{ __html: post.html }} />
				</div>
				<div className="clearfix"></div>
			</div>
		</Layout>
	);
};

export const postQuery = graphql`
	query BlogPostQuery($slug: String!) {
		markdownRemark(frontmatter: { slug: { eq: $slug } }) {
			frontmatter {
				slug
				title
				description
				indexImage {
					childImageSharp {
						resolutions(width: 600) {
							width
							height
							src
							srcSet
						}
					}
				}
				date(formatString: "YYYY-MM-DD")
			}
			html
		}
		sitePage(context: { slug: { eq: $slug } }) {
			path
		}
	}
`;

export default Template;
